import React from 'react';
import Formulario from './Formulario'
import Logo from '../IMG/Logo/Logo4_Evenet.png'
import Lo from '../IMG/Logo/SiigoAspel.png';
import '../Component/styles/Menu.css';
import { Link } from 'react-router-dom';
import { Reveal, Tween } from 'react-gsap';
import PropTypes from 'prop-types';
import {
  Card, Button, CardImg, CardTitle, CardDeck,
  CardSubtitle, CardBody, Modal, ModalHeader, ModalFooter, ModalBody,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook, faInstagram, faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import Bot from '../IMG/Logo/AAA.png';
import logo from '../IMG/Logo/oca-consultoria-logo-ii.png';
import "../Component/styles/Jumbotron.css";
import { FormGroup, Label, Input } from 'reactstrap';
import IG from '../IMG/footer/IG.png'
import FormularioSNT from '../paginas/Formulario/Conexion';
import MenuDropdown from './MenuDropDown';

class Menu extends React.Component {

  state = {
    abierto: false,
    abierto2: false,
  }
  abrirModal = () => {
    this.setState({ abierto: !this.state.abierto });
  }
  abrirModal2 = () => {
    this.setState({ abierto2: !this.state.abierto2 });
  }


  render() {


    window.addEventListener("scroll", function () {
      var header = document.querySelector("header");
      header.classList.toggle("abajo", window.scrollY > 0);
    })

    return (
      <>

        {/*1.- Modal Evenet */}
        <Modal isOpen={this.state.abierto} size="m">
          <ModalHeader className="bg-dark text-center" align="center">
            <h2 style={{ color: 'white', marginLeft: '80%' }} className="text-center" align="center">Contáctanos</h2>
          </ModalHeader>
          <ModalBody>
            <h7 className="container">Contacta con nosotros, es muy fácil, déjanos tus datos y nos comunicaremos contigo.</h7>
            <FormularioSNT />

            <h5 align="center">Buscanos en redes sociales </h5>
            <div class="text-left px-3 text-center">
              <a href="https://www.facebook.com/ocaconsultoriaenti" target="_blank" style={{ fontSize: '40px' }}>
                {/* <FontAwesomeIcon icon={faFacebook} /> */}
              </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Facebook</p>
              <a href="https://www.instagram.com/oca.consultoria/" target="_blank" style={{ fontSize: '40px' }}>
                {/* <FontAwesomeIcon icon={faInstagram} /> */}
              </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Instagram</p>
              <a href="https://wa.me/5569074817" target="_blank" style={{ fontSize: '40px' }}>
                {/* <FontAwesomeIcon icon={faWhatsappSquare} /> */}
              </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Whatsapp</p>
            </div>
          </ModalBody>

          <ModalFooter>
            {/* <Button color="primary">Enviar</Button> */}
            <Button color="secondary" onClick={this.abrirModal}>Cerrar</Button>
          </ModalFooter>
        </Modal>
        <header>
          {/* <a href="#" class="logo">OCA Consultoría en T.I.</a> */}
          <div className="" style={{ position: 'absolute' }}>
            <a href="/"><img class="navbar-brand m-auto" src={logo} width="35%" /></a> <br />
          </div>

          
          {/* <nav class="navbar navbar-dark bg-dark"> */}
          <nav class="navbar-expand-lg navbar-light py-2 container col-11 shadow" style={{backgroundColor:'transparent'}}>

            <div className="" align="right">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

              


              
              <ul class="navbar-nav m-auto">
                <li class="nav-item py-1"><a href="/" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Inicio </a></li>
                {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                <li class="nav-item py-1"><a href="/ServiciosOCA" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Servicios </a></li>
                <MenuDropdown />
                <li class="nav-item py-1"><a href="/Contacto" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Contáctanos </a></li>
                <li class="nav-item py-1"><a href="/Nosotros" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Nosotros </a></li>
              </ul>



            </div>
          </nav>

        </header>


        <section className="zona1">

        </section>

        {/* <container>
          <nav class="cambioC navbar navbar-expand-lg navbar-light fixed-top py-2 container col-11 shadow" >
            <div className="ligIma">
              <a href="/"><img class="navbar-brand py-1" src={logo} width="85%" /></a> <br />
            </div>
            <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <a className="nav-link cambioC letraSNT" href="/" > <b> Inicio </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/SistemasAspel" > <b> Sistemas Aspel </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/ServiciosOCA" > <b> Servicios </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/Contacto" > <b> Contacto </b> </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link cambioC letraSNT" href="/Nosotros"> <b> Nosotros </b> </a>
                </li>
              </ul>
            </div>
          </nav>
        </container> */}

        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <Formulario />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
        <img src={Lo} width="10%" className="imafi" ></img>
        {/* <img src={Bot} width="10%" height="180" className="imafi4" onClick={this.abrirModal} ></img> */}
        {/* <script type="text/javascript">
          windows.addEventListener("scroll", function(){
          var header = document.querySelector("header")});
          header.classList.toggle("abajo", windows.scrollY>0);
        </script> */}
      </>
    )

  }

}

export default Menu;