import React from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
// import Logero from '../JInicio';
import Menu from '../Menu';
// import Boton from '../Boton';
import Footer from '../Footer';
import '../../Component/styles/DAComercial.css';
import '../../Component/styles/Home.css';
import N from '../../IMG/Eventos/HE.png';
import y from '../../IMG/Eventos/Ev4.png';
import w from '../../IMG/Eventos/Ev2.png';
import v from '../../IMG/Eventos/Ev1.png';
import J from '../../IMG/Eventos/Ev6.png';
import K from '../../IMG/Eventos/Ev8.png';
import L from '../../IMG/Eventos/Ev7.png';
import boton from '../../IMG/Botones/megusta.png';
import boton2 from '../../IMG/Botones/compartir.png';
import Carrusel from '../CarruselEv';
import Carrusel_Ima from '../../paginas/DetallesAComercial/CarruselTEcnm';
import video from '../../Video/Aspel/Aspel SAE - Cumple con todas las disposiciones fiscales del CFDI 4.0.mp4';//SAE
import video2 from '../../Video/Aspel/Aspel COI - Haz que cuadre el nuevo CFDI 4.0 en tu contabilidad.mp4';
import video3 from '../../Video/Aspel/Siigo Aspel NOI _ Optimiza el manejo de la nomina y capital humano.mp4';
import video4 from '../../Video/Aspel/Aspel BANCO - Administra de forma eficiente los movimientos de tus cuentas bancarias.mp4';
import video5 from '../../Video/Aspel/Aspel PROD - Lleva un control preciso de tu produccion.mp4';
import video6 from '../../Video/Aspel/Aspel CAJA - Lleva el control efectivo de tu punto de venta.mp4';
import video7 from '../../Video/Aspel/Aspel FACTURE - Haz mas faciles y rapidos tus procesos de Facturacion.mp4';
import SiigoNube from '../../Video/Aspel/Siigo Nube Gestion.mp4'
import Aspel1 from '../../IMG/LogosAspel/ASPEL-ICONO VERT_SAE.png';
import Aspel2 from '../../IMG/LogosAspel/ASPEL-ICONO VERT_COI.png';
import Aspel3 from '../../IMG/LogosAspel/ASPEL-ICONO VERT_NOI.png';
import Aspel4 from '../../IMG/LogosAspel/ASPEL-ICONO VERT_BANCO.png';
import Aspel5 from '../../IMG/LogosAspel/ASPEL-ICONO VERT_PROD.png';
import Aspel6 from '../../IMG/LogosAspel/ASPEL-ICONO VERT_CAJA.png';
import Aspel7 from '../../IMG/LogosAspel/ASPEL-ICONO VERT_FACTURE.png';
import Medalla from '../../IMG/Logo/medalla-aspel-removebg-preview_orig.png';
import Rel1 from '../../IMG/Servicios/Datos curiosos/DatosCuriosos.jpg';
import Rel2 from '../../IMG/Servicios/Tips.jpg';
import Rel3 from '../../IMG/Servicios/Corte.jpg';
import Rel4 from '../../IMG/Servicios/Deducibles.jpg';
import Rele1 from '../../IMG/Servicios/Datos curiosos/DC1.jpg';
import Rele2 from '../../IMG/Servicios/Datos curiosos/DC2.jpg';
import Rele3 from '../../IMG/Servicios/Datos curiosos/DC3.jpg';
import Rele4 from '../../IMG/Servicios/Datos curiosos/DC4.jpg';
import Rele5 from '../../IMG/Servicios/Datos curiosos/DC5.jpg';
import izquierda from '../../IMG/Botones/Flecha_izquierda.png';
import derecha from '../../IMG/Botones/Flecha_derecha.png';

import Carru1 from '../CarruselRele1';
import Dautoriza1 from '../../IMG/LogosAspel/sello-print-color.jpg';
import Dautoriza2 from '..//../IMG/LogosAspel/sello-print-color2.jpg';
import SiggoBlack from '../../IMG/LogosAspel/Logo_Black.png'
import SiggoBlack2 from '../../IMG/LogosAspel/logo_black_negativo.png'
import CarruselTEcnm from '../../paginas/DetallesAComercial/CarruselTEcnm';
import CarruselTEcnm2 from '../../paginas/DetallesAComercial/CarruselTEcnm2';
import CarruselTEcnm3 from '../../paginas/DetallesAComercial/CarruselTEcnm3';
import CarruselTEcnm4 from '../../paginas/DetallesAComercial/CarruselTEcnm4';
// import '../../Component/styles/Stand1.css';

import { Reveal, Tween } from 'react-gsap';
// import { useFullScreen } from 'unc-react-creator';
import {
  Modal, ModalHeader, ModalBody, Card, Button, CardImg, CardTitle, CardText, CardDeck,
  CardSubtitle, CardBody, CardFooter, Jumbotron
} from 'reactstrap';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";


class AComercial1 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      SistemasModal: true
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState({
      SistemasModal: !this.state.SistemasModal
    });
  }



  state = {
    sae: false,
    coi: false,
    noi: false,
    banco: false,
    prod: false,
    caja: false,
    facture: false,
    Rel1: false,
    Rel2: false,
    Rel3: false,
    Rel4: false,
  }
  abrirModalSAE = () => {
    this.setState({ sae: !this.state.sae });
  }
  abrirModalCOI = () => {
    this.setState({ coi: !this.state.coi });
  }
  abrirModalNOI = () => {
    this.setState({ noi: !this.state.noi });
  }
  abrirModalBANCO = () => {
    this.setState({ banco: !this.state.banco });
  }
  abrirModalPROD = () => {
    this.setState({ prod: !this.state.prod });
  }
  abrirModalCAJA = () => {
    this.setState({ caja: !this.state.caja });
  }
  abrirModalFACTURE = () => {
    this.setState({ facture: !this.state.facture });
  }
  abrirModalREL1 = () => {
    this.setState({ Rel1: !this.state.Rel1 });
  }
  abrirModalREL2 = () => {
    this.setState({ Rel2: !this.state.Rel2 });
  }
  abrirModalREL3 = () => {
    this.setState({ Rel3: !this.state.Rel3 });
  }
  abrirModalREL4 = () => {
    this.setState({ Rel4: !this.state.Rel4 });
  }


  //Registro en GA
  componentDidMount = async () => {
    ReactGA.initialize('G-LV1HVF1Y4N');
    ReactGA.send({ hitType: "pageview", page: "/SistemasAspel", title: "Sistemas Aspel"});
  }

  render() {

    // const [callFullScreen] = useFullScreen();

    return (
      <>

        <Menu />
        <Modal isOpen={this.state.SistemasModal} toggle={this.toggle} size="lg" style={{ maxWidth: '800px', width: '100%' }}
          // aria-labelledby="example-modal-sizes-title-sm" centered 
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <button type="button" className="close US2" aria-label="Close" onClick={this.toggle} style={{ backgroundColor: 'steelblue' }}>
            <span aria-hidden="true" style={{ color: 'blue' }}>&times;</span>
          </button>
          {/* <center style={{ backgroundColor: '#B2B6CF' }}> */}
          <center className="LinearSistemas">
            <video src={SiigoNube} width="100%" loop controls muted autoplay="true" preload="auto"></video>
            <h3>Siigo Nube - Sistema Administrativo y Contable </h3>
          </center>
        </Modal>
        {/* ---------------------------------------------------------- */}
        {/* Seccion Modales "PDF" de Sistemas Aspel */}
        {/* ---------------------------------------------------------- */}

        {/* SAE */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.sae} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger p-3" style={{ backgroundColor: 'white' }} aria-label="Close" onClick={this.abrirModalSAE}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
            {/* <p>Hola</p> */}
          </ModalHeader>
          <iframe className="Mapa" src="https://ocaconsultoria.mx/Aspel/ficha-tecnica-sae-8.pdf" allowfullscreen="" width="100%" height="800" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          {/* COI */}
        </Modal>
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.coi} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger p-3" style={{ backgroundColor: 'white' }} aria-label="Close" onClick={this.abrirModalCOI}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <iframe className="Mapa" src="https://ocaconsultoria.mx/Aspel/ficha-tecnica-coi.pdf" allowfullscreen="" width="100%" height="800" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Modal>

        {/* NOI */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.noi} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger p-3" style={{ backgroundColor: 'white' }} aria-label="Close" onClick={this.abrirModalNOI}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <iframe className="Mapa" src="https://ocaconsultoria.mx/Aspel/ficha-tecnica-noi.pdf" allowfullscreen="" width="100%" height="800" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Modal>

        {/* BANCO */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.banco} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger p-3" style={{ backgroundColor: 'white' }} aria-label="Close" onClick={this.abrirModalBANCO}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <iframe className="Mapa" src="https://ocaconsultoria.mx/Aspel/ficha-tecnica-banco.pdf" allowfullscreen="" width="100%" height="800" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Modal>

        {/* PROD */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.prod} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger p-3" style={{ backgroundColor: 'white' }} aria-label="Close" onClick={this.abrirModalPROD}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <iframe className="Mapa" src="https://ocaconsultoria.mx/Aspel/manual-aspel-sistema-de-control-de-produccion.pdf" allowfullscreen="" width="100%" height="800" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Modal>

        {/* CAJA */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.caja} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger p-3" style={{ backgroundColor: 'white' }} aria-label="Close" onClick={this.abrirModalCAJA}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <iframe className="Mapa" src="https://ocaconsultoria.mx/Aspel/ficha-tecnica-caja.pdf" allowfullscreen="" width="100%" height="800" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Modal>

        {/* FACTURE */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.facture} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger p-3" style={{ backgroundColor: 'white' }} aria-label="Close" onClick={this.abrirModalFACTURE}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <iframe className="Mapa" src="https://ocaconsultoria.mx/Aspel/ficha-tecnica-facture.pdf" allowfullscreen="" width="100%" height="800" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Modal>

        {/* ---------------------------------------------------------- */}
        {/* Termina Seccion Modales "PDF" de Sistemas Aspel */}
        {/* ---------------------------------------------------------- */}

        {/* ---------------------------------------------------------- */}
        {/* Seccion Modales Áreas de Interés */}
        {/* ---------------------------------------------------------- */}

        {/* CARD_1 */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.Rel1} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL1}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <ModalBody>
            {/* <Carrusel_Ima /> */}
          </ModalBody>
        </Modal>


        {/* CARD_2 */}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.Rel2} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL2}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <ModalBody>
            <img src={Rel2} className="" width="100%" ></img>
            {/* <Carru1 /> */}
          </ModalBody>
        </Modal>
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.Rel3} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL3}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <ModalBody>
            <img src={Rel3} className="" width="100%" ></img>
            {/* <Carru1 /> */}
          </ModalBody>
        </Modal>
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered isOpen={this.state.Rel4} sixe="lg">
          <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
            <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

              <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL4}>
                <span aria-hidden="true">&times;</span>
              </button></h6>
          </ModalHeader>
          <ModalBody>
            <img src={Rel4} className="" width="100%" ></img>
            {/* <Carru1 /> */}
          </ModalBody>
        </Modal>
        <main role="main" className="flex-shrink-0 mt-5">

          <div className="pt-3">
            <div className="container-fluid align-items-center">
              <div class="row">


                <div className="col-sm-12 px-1 pb-1" >
                  <Card color="secondary"
                    outline>
                    <div className="card-header px-0 mb-0 text-center" >
                      <Reveal repeat>
                        <Tween
                          from={{ opacity: -10, rotation: -15 }}
                          to={{ opacity: 10, rotation: 0 }}
                          duration={1}
                        >
                          <Jumbotron className="p-1">
                            <h1 className="display-4 text-center InfraJumbo" >Sistemas Siggo Aspel <br /><img src={Medalla} className="BrilloSNT" width="3%" /><img src={Medalla} className="BrilloSNT" width="6%" /><img src={Medalla} className="BrilloSNT" width="3%" /></h1>
                          </Jumbotron>
                          {/* <h2 class="AH2">Sistemas Aspel<img src={Medalla} className="BrilloSNT" width="20%" /></h2> */}
                        </Tween>
                      </Reveal>
                    </div>
                    <div className="card-title px-0 mb-2">
                      <Reveal repeat>
                        <br />
                        {/* <p class="card-text"> */}
                        <Tween
                          from={{ opacity: -10, rotation: 1 }}
                          to={{ opacity: 9, rotation: 0 }}
                          duration={2}
                        >
                          <p className="BrilloSNT2 text-center">+Más de 14 años de experiencia</p>
                          {/* <h4>Distribuidor Integral Certificado</h4> */}
                        </Tween>
                      </Reveal>
                    </div>
                    <div className="card-title px-0 mb-1" >
                      <Reveal repeat>
                        <Tween
                          from={{ opacity: -10, rotation: -1 }}
                          to={{ opacity: 8, rotation: 0 }}
                          duration={3}
                        >
                          <h4 className="text-center px-1">Distribuidor Integral Certificado</h4>
                          <center>
                            <img src={Dautoriza1} className="justify-content-space-around" width="20%" />
                            <img src={SiggoBlack} className="justify-content-space-around" width="20%" />
                            <img src={Dautoriza2} className="justify-content-space-around" width="20%" />
                          </center>
                          {/* <p className="BrilloSNT2">+Más de 14 años de experiencia</p> */}
                        </Tween>
                      </Reveal>
                    </div>
                    <div className="card-body px-0 mb-0 scrollSA" style={{ lineHeight: '1.3em' }}>
                      <p className="px-1 text-center">Contamos con un equipo de ingenieros rigurosamente ​evaluados, certificados y actualizados en todos los sistemas de la línea Siggo Aspel, capaces de brindar soluciones integrales ​de alta calidad.</p>
                      {/* <Reveal repeat>
                        <Tween
                          from={{ opacity: -10, rotation: 1 }}
                          to={{ opacity: 7, rotation: 0 }}
                          duration={4}
                        >
                          <p className="px-1" align="justify">Contamos con un equipo de ingenieros rigurosamente ​evaluados, certificados y actualizados en todos los sistemas de la línea Aspel, capaces de brindar soluciones integrales ​de alta calidad.</p>
                        </Tween>
                      </Reveal> */}
                    </div>
                    <div className="card-footer FoSA">
                      <Button className="col-12" href="/contacto" color="primary" outline style={{ borderRadius: '20px' }}>
                        Conocer más...
                      </Button>
                      {/* <a class="btn btn-sm btn-primary col-12" href="/Contacto" role="button">Conocer más...</a> */}
                    </div>
                  </Card>

                </div>
                <div class="container col-sm-10 align-items-center">
                  <div class="row SombraSNT">
                    <div className="card col-12 LinearSistemas2 ">
                      <div className="card-body">

                        <h2 class="AH22 text-center">SOLUCIONES PARA TU EMPRESA.</h2>
                        <Carrusel />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span id="SAE"></span>
              <hr color="#292563" />
            </div>
          </div >
          {/* Seccion video */}
          < div class="container" >
            <div className="container align-items-center">
              <div class="row">
                <div class="col-sm-6 px-4 align-items-center PruebaSNT4">
                  <br />
                  <br />
                  <div className="card-body" style={{ lineHeight: '1.5em' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 10 }}
                        duration={1.5}
                      >
                        <h2 class="AH3">¿Ya conoces nuestras soluciones?</h2>
                      </Tween>

                      <p class="card-text text-center" >
                        <Tween
                          from={{ opacity: -20 }}
                          to={{ opacity: 10 }}
                          duration={2.5}
                        >
                          <p>Mira el siguiente video para conocer los sistemas <strong>Administrativos empresariales</strong> que tenemos para ti.</p>
                        </Tween>
                      </p>
                    </Reveal>
                  </div>
                  <br />
                  <br />
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                  </div>
                </div>
                <div class="container col-sm-6 px-2 py-1">
                  <video src={video} width="100%" height="320px" loop controls muted autoplay="true" preload="auto"></video>
                </div>
              </div>
            </div>
            <hr color="#292563" style={{ backgroundColor: 'red' }} />
          </div >
          {/* Termina seccion video */}
          {/* -------------------------------------- */}
          <div className="sntS" >
            <div class="container justify-content-between">
              <section className="text-left ">
                {/* Inicia sección para animación */}
                <Reveal repeat>
                  <Tween
                    from={{ x: '-70px', opacity: 0, rotation: 0.5 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={3}
                  >
                    <h2 class="Tit1 text-left px-5 SomSAE">SAE</h2>
                  </Tween>
                  {/* Termina sección para animación */}
                </Reveal>
              </section>
            </div>
            <div className="container align-items-center">
              <div class="row">
                <div class="container col-sm-4">
                  {/* Inicia sección para animación */}
                  <Reveal repeat>
                    <Tween
                      from={{ x: '-70px', opacity: -10, rotation: 0.5 }}
                      to={{ x: '0px', opacity: 10, rotation: 0 }}
                      duration={1.3}
                    >
                      <img src={Aspel1} className="" width="50%" ></img>
                    </Tween>
                  </Reveal>
                  {/* Termina sección para animación*/}
                </div>
                <div class="col-sm-8 px-0" style={{ lineHeight: '1.5em' }}>
                  <Reveal repeat>
                    <Tween
                      from={{ opacity: -20 }}
                      to={{ opacity: 20 }}
                      duration={3.11}
                    >
                      <h2 class="AH2 px-3">Sistema Administrativo</h2>
                    </Tween>
                  </Reveal>
                  <div className="card-body text-left">
                    <p class="card-text text-justify">
                      {/* Inicia sección para animación de texto*/}
                      <Reveal repeat>
                        <Tween
                          from={{ x: '10px', y: '-30px', opacity: 0, rotation: 8 }}
                          to={{ x: '0px', y: '0px', opacity: 1, rotation: 0 }}
                          duration={1}
                        >
                          <p>Controla el ciclo de todas las operaciones de compra-venta de la empresa como: inventarios, clientes, facturación, cuentas por cobrar, vendedores, compras, proveedores y cuentas por pagar; automatizando eficientemente los procesos administrativos. Ofrece movilidad a las empresas conectando las sucursales remotamente y por medio de dispositivos móviles...</p>
                          {/* <iframe className="Mapa"  src="https://ocaconsultoria.mx/Aspel/ficha-tecnica-sae-8.pdf" allowfullscreen="" width="100%" height="400" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                          {/* onClick={this.abrirModal2} */}
                          {/* <Link to={'https://ocaconsultoria.mx/Aspel/ficha-tecnica-sae-8.pdf'} type="button" class="btn btn-danger col-12"> Conocer más..</Link> */}
                          <br />
                          <Link onClick={this.abrirModalSAE} type="button" class="btn btn-danger col-12" style={{ borderRadius: '20px' }}> Conocer más..</Link>
                        </Tween>
                      </Reveal>
                      {/* Termina sección para animación de texto*/}
                    </p>
                  </div>
                </div>
              </div>
              <hr id="COI" className='hrSNT' style={{ backgroundColor: 'red' }} />
            </div>
          </div>
          {/* Seccion video */}
          <div class="container">
            <div className="container align-items-center ">
              <div class="row">
                <div class="container col-sm-6 px-2">
                  <video src={video2} width="100%" height="320px" loop controls muted autoplay="true" preload="auto"></video>

                </div>
                <div class="col-sm-6 px-0 align-items-center PruebaSNT3">
                  <br />
                  <br />
                  <div className="card-body" style={{ lineHeight: '1.5em' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 10 }}
                        duration={1.5}
                      >
                        <h2 class="AH3 text-justify">Contabilidad Integral</h2>
                      </Tween>

                      <p class="card-text text-justify">
                        <Tween
                          from={{ opacity: -20 }}
                          to={{ opacity: 8 }}
                          duration={2.5}
                        >
                          <p>Mira el siguiente video para conocer las funcionalidades del sistema de <strong>Contabilidad Integral</strong> que tenemos para ti.</p>
                        </Tween>
                      </p>
                    </Reveal>
                  </div>
                  <br />
                  <br />
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr color="#292563" id="SAE" style={{ backgroundColor: '#00ADD9' }} />
          </div>
          {/* Termina seccion video */}

          <div className="sntC">
            <div class="container justify-content-between">
              <section className="text-right">
                {/* Seccion para darle movimiento*/}
                <Reveal repeat>
                  <Tween
                    from={{ x: '70px', opacity: 0, rotation: 0.5 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={3}
                  >
                    <h2 class="Tit2 text-right px-5 SomCOI">COI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h2>
                  </Tween>
                </Reveal>
                {/* Termina sección para darle movimiento */}
              </section>
            </div>
            <div class="container">
              <div className=" align-items-center">
                <div class="row">
                  <div class="col-sm-8 py-2 px-0" style={{ lineHeight: '1.5em' }}>
                    <br />
                    <br />
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 20 }}
                        duration={3.11}
                      >
                        <h2 class="AH2 card-title text-left">Sistema de Contabilidad Integral</h2>
                      </Tween>
                    </Reveal>
                    <div className="card-body px-0 justify-content-between">
                      <p class="card-text pr-2 text-justify">
                        {/* Inicia sección para animación de texto*/}
                        <Reveal repeat>
                          <Tween
                            from={{ x: '-40px', y: '-30px', opacity: 0, rotation: -8 }}
                            to={{ x: '0px', y: '0px', opacity: 1, rotation: 0 }}
                            duration={1}
                          >
                            <p className="pb-2">Procesa, integra y mantiene actualizada la información contable y fiscal de la empresa en forma segura y confiable. Cumple con las diferentes especificaciones y funciones para el manejo de la contabilidad electrónica, de acuerdo a las disposiciones fiscales vigentes. Proporciona diversos reportes, documentos de trabajo y gráficas que permiten soportar y evaluar el estado financiero de la organización...</p>
                          </Tween>
                        </Reveal>
                        {/* Termina sección para animación de texto*/}
                      </p>
                    </div>
                    <CardFooter className="Fot1" align="center" style={{ borderRadius: '20px' }}><a onClick={this.abrirModalCOI} type="button" class="text-info">Ver más...</a></CardFooter>
                  </div>
                  <div class="container col-sm-4 px-0">
                    {/* Inicia sección para darle movimiento*/}
                    <Reveal repeat>
                      <Tween
                        from={{ x: '70px', opacity: 0, rotation: 0.5 }}
                        to={{ x: '0px', opacity: 1, rotation: 0 }}
                        duration={1.3}
                      >
                        <img src={Aspel2} className="" width="50%"></img>
                      </Tween>
                    </Reveal>
                    {/* Termina sección para darle movimiento*/}
                  </div>
                </div>
              </div>
              <hr className='hrSNT' id="NOI" style={{ backgroundColor: '#00ADD9' }} />
            </div>
          </div>
          {/* ----------------------------------------------------------- */}
          {/* Seccion video */}
          <div class="container">
            <div className="container align-items-center">
              <div class="row">

                <div class="col-sm-6 px-0 align-items-center PruebaSNT2">
                  <br />
                  <br />
                  <div className="card-body" style={{ lineHeight: '1.5em' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 10 }}
                        duration={1.5}
                      >
                        <h2 class="AH3 text-justify">Nómina Integral</h2>
                      </Tween>

                      <p class="card-text text-justify">
                        <Tween
                          from={{ opacity: -20 }}
                          to={{ opacity: 8 }}
                          duration={2.5}
                        >
                          <p>Mira el siguiente video para conocer las funcionalidades del sistema de <strong>Nómina Integral</strong> que tenemos para ti.</p>
                        </Tween>
                      </p>
                    </Reveal>
                  </div>
                  <br />
                  <br />
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                  </div>
                </div>
                <div class="container col-sm-6 px-2 py-1">
                  <video src={video3} width="100%" height="320px" loop controls muted autoplay="true" preload="auto"></video>
                </div>
              </div>
            </div>
            <hr color="#292563" id="SAE" style={{ backgroundColor: '#FD8204' }} />
          </div>
          {/* Termina seccion video */}
          <div className="sntN">
            <div class="container justify-content-between">
              <section className="text-left ">
                {/* Inicia sección para darle movimiento*/}
                <Reveal repeat>
                  <Tween
                    from={{ x: '-70px', opacity: 0, rotation: 0.5 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.3}
                  >
                    <h2 class="Tit1 text-left px-5" style={{ color: '#FD8204' }}>&nbsp;NOI</h2>
                  </Tween>
                </Reveal>
                {/* Termina sección para darle movimiento*/}
              </section>
            </div>
            <div className="container align-items-center">
              <div class="row">
                <div class="container col-sm-4">
                  {/* Inicia sección para darle movimiento*/}
                  <Reveal repeat>
                    <Tween
                      from={{ x: '-70px', opacity: 0, rotation: 0.5 }}
                      to={{ x: '0px', opacity: 1, rotation: 0 }}
                      duration={1.3}
                    >
                      <img src={Aspel3} className="" width="50%" ></img>
                    </Tween>
                  </Reveal>
                  {/* Termina sección para darle movimiento*/}
                </div>
                <div class="col-sm-8 px-0" style={{ lineHeight: '1.5em' }}>
                  {/* Inicia sección para darle movimiento*/}
                  <Reveal repeat>
                    <Tween
                      from={{ y: '-70px', opacity: 0, rotation: 0 }}
                      to={{ y: '0px', opacity: 1, rotation: 0 }}
                      duration={1.3}
                    >
                      <h2 class="AH2 px-3">Sistema de Nómina Integral</h2>
                    </Tween>
                  </Reveal>
                  {/* Termina sección de movimiento */}
                  <div className="card-body text-left">
                    <p class="card-text text-justify">
                      {/* Inicia sección para darle movimiento*/}
                      <Reveal repeat>
                        <Tween
                          from={{ y: '70px', opacity: 0, rotation: 0 }}
                          to={{ y: '0px', opacity: 1, rotation: 0 }}
                          duration={1.3}
                        >
                          <p>Automatiza el control de los aspectos más importantes de la nómina, su fácil manejo ofrecen un cálculo exacto de las percepciones y deducciones de los trabajadores atendiendo los requerimientos específicos de la empresa. Calcula la retención de ISR, cuotas obrero-patronales IMSS e INFONAVIT, subsidio para el empleo y previsión social...</p>
                        </Tween>
                      </Reveal>
                      {/* Termina sección de movimiento */}
                      <br />
                      <Link onClick={this.abrirModalNOI} type="button" class="btn col-12" style={{ backgroundColor: '#FD8204', borderRadius: '20px' }}>Conocer más..</Link>
                    </p>
                  </div>
                </div>
              </div>
              <hr color="#292563" id="BANCO" className='hrSNT' style={{ backgroundColor: '#FD8204' }} />
            </div>
          </div>

          {/* Seccion video */}
          <div class="container">
            <div className="container align-items-center">
              <div class="row">
                <div class="container col-sm-6 px-2">
                  <video src={video4} width="100%" height="320px" loop controls muted autoplay="true" preload="auto"></video>

                </div>
                <div class="col-sm-6 px-0 align-items-center PruebaSNT" >
                  <br />
                  <br />
                  <div className="card-body" style={{ lineHeight: '1.5em' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 10 }}
                        duration={1.5}
                      >
                        <h2 class="AH3 text-justify">Sistema de Control de Bancos</h2>
                      </Tween>

                      <p class="card-text text-justify">
                        <Tween
                          from={{ opacity: -20 }}
                          to={{ opacity: 8 }}
                          duration={2.5}
                        >
                          <p>Mira el siguiente video para conocer las funcionalidades del sistema de <strong>administración financiera, </strong> automatiza tus operaciones y mantiene al día los registros de los ingresos y egresos</p>
                        </Tween>
                      </p>
                    </Reveal>
                  </div>
                  <br />
                  <br />
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr color="#292563" id="SAE" style={{ backgroundColor: '#94D60A' }} />
          </div>
          {/* Termina seccion video */}

          <div className="sntB" >

            <div class="container justify-content-between">
              <section className="text-right">
                {/* Inicia sección para darle movimiento*/}
                <Reveal repeat>
                  <Tween
                    from={{ x: '70px', opacity: 0, rotation: 0.5 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.3}
                  >
                    <h2 class="Tit2 text-right px-4" style={{ color: '#94D60A' }}>BANCO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                  </Tween>
                </Reveal>
                {/* Termina sección para darle movimiento*/}
              </section>
            </div>
            <div class="container">
              <div className="container align-items-center">
                <div class="row">
                  <div class="col-sm-8 py-2 px-0" style={{ lineHeight: '1.5em' }}>
                    <br />
                    <br />
                    {/* Inicia sección para darle movimiento*/}
                    <Reveal repeat>
                      <Tween
                        from={{ y: '-70px', opacity: 0, rotation: 0 }}
                        to={{ y: '0px', opacity: 1, rotation: 0 }}
                        duration={1.3}
                      >
                        <h2 class="AH2 card-title text-left">Sistema Bancario</h2>
                      </Tween>
                    </Reveal>
                    {/* Termina sección de movimiento */}
                    <div className="card-body px-0 justify-content-between">
                      <p class="card-text pr-2 text-justify">
                        {/* Inicia sección para darle movimiento*/}
                        <Reveal repeat>
                          <Tween
                            from={{ y: '70px', opacity: 0, rotation: 0 }}
                            to={{ y: '0px', opacity: 1, rotation: 0 }}
                            duration={1.3}
                          >
                            <p className="pb-2">Controla eficientemente los ingresos, egresos y movimientos de cualquier cuenta bancaria, tanto en moneda nacional como extranjera. Ofrece información financiera en todo momento como Estados de cuenta, Diarios de bancos, Flujo de efectivo, entre otros. Además, permite la programación de movimientos periódicos, así como la conciliación electrónica con las principales instituciones financieras....</p>
                          </Tween>
                        </Reveal>
                        {/* Termina sección de movimiento */}
                      </p>
                    </div>
                    <br />
                    <CardFooter className="Fot1" align="center" style={{ backgroundColor: '#A2A9AE', borderRadius: '20px' }}><a onClick={this.abrirModalBANCO} type="button" style={{ color: 'Green' }}>Ver más...</a></CardFooter>
                  </div>
                  <div class="container col-sm-4 px-0">
                    {/* Inicia sección para darle movimiento*/}
                    <Reveal repeat>
                      <Tween
                        from={{ x: '70px', opacity: 0, rotation: 0.5 }}
                        to={{ x: '0px', opacity: 1, rotation: 0 }}
                        duration={1.3}
                      >
                        <img src={Aspel4} className="" width="50%"></img>
                      </Tween>
                    </Reveal>
                    {/* Termina sección para darle movimiento*/}
                  </div>
                </div>
              </div>
              <hr id="PROD" className='hrSNT' style={{ backgroundColor: '#94D60A' }} />
            </div >
          </div>
          {/* Termina seccion de Aspel Banco */}

          {/* Seccion video */}
          <div class="container">
            <div className="container align-items-center">
              <div class="row">

                <div class="col-sm-6 px-0 align-items-center PruebaSNT5">
                  <br />
                  <div className="card-body" style={{ lineHeight: '1.5em' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 10 }}
                        duration={1.5}
                      >
                        <h2 class="AH3 text-center">Procesos de manufactura</h2>
                      </Tween>

                      <p class="card-text text-justify">
                        <Tween
                          from={{ opacity: -20 }}
                          to={{ opacity: 8 }}
                          duration={2.5}
                        >
                          <p>Te presentamos el siguiente video para conocer las funcionalidades del sistema de <strong> PROD </strong></p>
                        </Tween>
                      </p>
                    </Reveal>
                  </div>
                  <br />
                  <br />
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                  </div>
                </div>
                <div class="container col-sm-6 px-2 py-1">
                  <video src={video5} width="100%" height="320px" loop controls muted autoplay="true" preload="auto"></video>

                </div>
              </div>
            </div>
            <hr color="#292563" id="SAE" style={{ backgroundColor: '#4D4084' }} />
          </div>
          {/* Termina seccion video */}

          <div className="sntP">

            <div class="container justify-content-between">
              <section className="text-left ">
                {/* Inicia sección para darle movimiento*/}
                <Reveal repeat>
                  <Tween
                    from={{ x: '-6%', opacity: 0, rotation: 0.5 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.3}
                  >
                    <h2 class="Tit1 text-left px-5" style={{ color: '#4D4084' }}>PROD</h2>
                  </Tween>
                </Reveal>
                {/* Termina sección para darle movimiento*/}
              </section>
            </div>
            <div className="container align-items-center">
              <div class="row">

                <div class="container col-sm-4">
                  {/* Inicia sección para darle movimiento*/}
                  <Reveal repeat>
                    <Tween
                      from={{ x: '-6%', opacity: 0, rotation: 0.5 }}
                      to={{ x: '0px', opacity: 1, rotation: 0 }}
                      duration={1.3}
                    >
                      <img src={Aspel5} className="" width="50%" ></img>
                    </Tween>
                  </Reveal>
                  {/* Termina sección para darle movimiento*/}
                </div>
                <div class="col-sm-8 px-0" style={{ lineHeight: '1.5em' }}>
                  {/* Inicia sección para darle movimiento*/}
                  <Reveal repeat>
                    <Tween
                      from={{ x: '1px', opacity: 0, rotation: 0.5 }}
                      to={{ x: '0px', opacity: 1, rotation: 0 }}
                      duration={1.3}
                    >
                      <h2 class="AH2 px-3">Sistema de Producción Integral</h2>
                    </Tween>
                  </Reveal>
                  {/* Termina sección para darle movimiento*/}
                  <div className="card-body text-left">
                    <p class="card-text text-justify">
                      {/* Inicia sección para darle movimiento*/}
                      <Reveal repeat>
                        <Tween
                          from={{ x: '1px', opacity: 0, rotation: 0.5 }}
                          to={{ x: '0px', opacity: 1, rotation: 0 }}
                          duration={1.3}
                        >
                          <p>Permite la planeación y control de los procesos de fabricación de la empresa, asegurando una óptima administración de inventarios y costos, proporciona además, un eficiente seguimiento a las órdenes de producción mejorando los tiempos de entrega. Interactúa con Aspel-SAE 8.0 y 7.0 del que obtiene información de materia prima y sub-ensambles, para realizar los procesos de producción y posteriormente actualizar el inventario con los productos terminados...</p>
                        </Tween>
                      </Reveal>
                      {/* Termina sección para darle movimiento*/}
                      {/* <p>Permite la planeación y control de los procesos de fabricación de la empresa, asegurando una óptima administración de inventarios y costos, proporciona además, un eficiente seguimiento a las órdenes de producción mejorando los tiempos de entrega. Interactúa con Aspel-SAE 8.0 y 7.0 del que obtiene información de materia prima y sub-ensambles, para realizar los procesos de producción y posteriormente actualizar el inventario con los productos terminados...</p> */}
                      <br />
                      <Link onClick={this.abrirModalPROD} type="button" class="btn btn col-12" style={{ backgroundColor: '#4D4084', color: 'white', borderRadius: '20px' }}> Conocer más..</Link>
                    </p>
                  </div>
                </div>
              </div>
              <hr color="#292563" id="CAJA" className='hrSNT' style={{ backgroundColor: '#4D4084' }} />
            </div>
          </div>


          {/* Seccion video */}
          <div class="container">
            <div className="container align-items-center">
              <div class="row">
                <div class="container col-sm-6 px-2">
                  <video src={video6} width="100%" height="320px" loop controls muted autoplay="true" preload="auto"></video>
                </div>
                <div class="col-sm-6 px-0 align-items-center PruebaSNT6" >
                  <br />
                  <br />
                  <div className="card-body" style={{ lineHeight: '1.5em' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 10 }}
                        duration={1.5}
                      >
                        <h2 class="AH3 text-justify">Sistema Punto de Venta </h2>
                      </Tween>

                      <p class="card-text text-justify">
                        <Tween
                          from={{ opacity: -20 }}
                          to={{ opacity: 8 }}
                          duration={2.5}
                        >
                          <p>Mira el siguiente video para conocer las funcionalidades del sistema de <strong>CAJA</strong>, convierte cualquier equipo de cómputo en un punto de venta con un moderno Sistema de caja registradora.</p>
                        </Tween>
                      </p>
                    </Reveal>
                  </div>
                  <br />
                  <br />
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr color="#292563" id="SAE" style={{ backgroundColor: '#F3C300' }} />
          </div>
          {/* Termina seccion video */}


          <div className="sntCA">
            <div class="container justify-content-between">
              <section className="text-right">
                {/* Inicia sección para darle movimiento*/}
                <Reveal repeat>
                  <Tween
                    from={{ x: '70PX', opacity: 0, rotation: 0.5 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.3}
                  >
                    <h2 class="Tit2 text-right px-5" style={{ color: '#F3C300' }}>CAJA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                  </Tween>
                </Reveal>
                {/* Termina sección para darle movimiento*/}
              </section>
            </div>
            <div class="container">
              <div className="container align-items-center">
                <div class="row">
                  <div class="col-sm-8 py-2 px-0" style={{ lineHeight: '1.5em' }}>
                    <br />
                    <br />
                    {/* Inicia sección para darle movimiento*/}
                    <Reveal repeat>
                      <Tween
                        from={{ x: '-70PX', opacity: 0, rotation: 0.5 }}
                        to={{ x: '0px', opacity: 1, rotation: 0 }}
                        duration={1.3}
                      >
                        <h2 class="AH2 card-title text-left">Sistema Punto de Venta</h2>
                      </Tween>
                    </Reveal>
                    {/* Termina sección para darle movimiento*/}
                    {/* <h2 class="AH2 card-title text-left">Sistema Punto de Venta</h2> */}
                    <div className="card-body px-0 justify-content-between">
                      <p class="card-text pr-2 text-justify">
                        {/* Inicia sección para darle movimiento*/}
                        <Reveal repeat>
                          <Tween
                            from={{ x: '-70PX', opacity: 0, rotation: 0.5 }}
                            to={{ x: '0px', opacity: 1, rotation: 0 }}
                            duration={1.3}
                          >
                            <p className="pb-2">Convierte un equipo de cómputo en un punto de venta capaz de operar con pantallas de torreta, lectores ópticos de código de barras, básculas, cajones de dinero, lectores de tarjetas bancarias e impresoras de tickets, entre otros dispositivos, para agilizar las operaciones de venta. Permite el manejo y control de las operaciones de un punto de venta, ya que con sólo ingresar los productos del inventario, el sistema está listo para capturar notas de venta e imprimir tickets o facturas, así como realizar devoluciones, cancelaciones y notas de crédito. ..</p>
                          </Tween>
                        </Reveal>
                        {/* Termina sección para darle movimiento*/}
                        {/* <p>Convierte un equipo de cómputo en un punto de venta capaz de operar con pantallas de torreta, lectores ópticos de código de barras, básculas, cajones de dinero, lectores de tarjetas bancarias e impresoras de tickets, entre otros dispositivos, para agilizar las operaciones de venta. Aspel-CAJA 3.5 permite el manejo y control de las operaciones de un punto de venta, ya que con sólo ingresar los productos del inventario, el sistema está listo para capturar notas de venta e imprimir tickets o facturas, así como realizar devoluciones, cancelaciones y notas de crédito. ..</p> */}
                      </p>
                    </div>
                    <CardFooter className="Fot1" align="center" style={{ backgroundColor: '#A1A9AD', borderRadius: '20px' }}><a onClick={this.abrirModalCAJA} type="button" class="text-warning">Ver más...</a>
                    </CardFooter>
                  </div>
                  <div class="container col-sm-4 px-0">
                    {/* Inicia sección para darle movimiento*/}
                    <Reveal repeat>
                      <Tween
                        from={{ x: '70PX', opacity: 0, rotation: 0.5 }}
                        to={{ x: '0px', opacity: 1, rotation: 0 }}
                        duration={1.3}
                      >
                        <img src={Aspel6} className="" width="50%"></img>
                      </Tween>
                    </Reveal>
                    {/* Termina sección para darle movimiento*/}
                  </div>
                </div>
              </div>
              <hr color="#292563" id="FACTURE" style={{ backgroundColor: '#F3C300' }} />
            </div>
          </div>
          {/* ----------------------------------------------------------- */}
          {/* Seccion video */}
          <div class="container">
            <div className="container align-items-center">
              <div class="row">

                <div class="col-sm-6 px-0 align-items-center PruebaSNT7">
                  <br />
                  <div className="card-body" style={{ lineHeight: '1.5em' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20 }}
                        to={{ opacity: 10 }}
                        duration={1.5}
                      >
                        <h2 class="AH3 text-center">Simplifica tu facturación</h2>
                      </Tween>
                      <p class="card-text text-justify">
                        <Tween
                          from={{ opacity: -20 }}
                          to={{ opacity: 8 }}
                          duration={2.5}
                        >
                          <p>Te presentamos el siguiente video para conocer las funcionalidades del sistema de <strong> FACTURE</strong></p>
                        </Tween>
                      </p>
                    </Reveal>
                  </div>
                  <br />
                  <br />
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                    <div class="p-2 bd-highlight align-items-end">
                    </div>
                  </div>
                </div>
                <div class="container col-sm-6 px-2 py-1">
                  <video src={video7} width="100%" height="320px" loop controls muted autoplay="true" preload="auto"></video>
                </div>
              </div>
            </div>
            <hr color="#292563" id="SAE" style={{ backgroundColor: '#4D4084' }} />
          </div>
          {/* Termina seccion video */}
          <div className="sntFA">
            <div class="container justify-content-between">
              <section className="text-left ">
                {/* Inicia sección para darle movimiento*/}
                <Reveal repeat>
                  <Tween
                    from={{ x: '-70PX', opacity: 0, rotation: 0.5 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.3}
                  >
                    <h2 class="Tit1 text-left px-4" style={{ color: '#AD172B' }}>FACTURE</h2>
                  </Tween>
                </Reveal>
                {/* Termina sección para darle movimiento*/}
              </section>
            </div>
            <div className="container align-items-center">
              <div class="row">
                <div class="container col-sm-4" >
                  {/* Inicia sección para darle movimiento*/}
                  <Reveal repeat>
                    <Tween
                      from={{ x: '-70PX', opacity: 0, rotation: 0.5 }}
                      to={{ x: '0px', opacity: 1, rotation: 0 }}
                      duration={1.3}
                    >
                      <img src={Aspel7} className="" width="50%" ></img>
                    </Tween>
                  </Reveal>
                  {/* Termina sección para darle movimiento*/}
                </div>
                <div class="col-sm-8 px-0" style={{ lineHeight: '1.5em' }}>
                  {/* Inicia sección para darle movimiento*/}
                  <Reveal repeat>
                    <Tween
                      from={{ y: '-70PX', opacity: 0, rotation: 0.5 }}
                      to={{ y: '0px', opacity: 1, rotation: 0 }}
                      duration={1.3}
                    >
                      <h2 class="AH2 px-3">Sistema de Facturación</h2>
                    </Tween>
                  </Reveal>
                  {/* Termina sección para darle movimiento*/}
                  <div className="card-body text-left">
                    <p class="card-text text-justify">
                      {/* Inicia sección para darle movimiento*/}
                      <Reveal repeat>
                        <Tween
                          from={{ y: '70PX', opacity: 0, rotation: 0.5 }}
                          to={{ y: '0px', opacity: 1, rotation: 0 }}
                          duration={1.3}
                        >
                          <p>Genera Comprobantes Fiscales Digitales por Internet (CFDI) en segundos de acuerdo con las disposiciones fiscales vigentes. Captura fácilmente facturas, notas de crédito, recibos de honorarios, de arrendamiento y otros comprobantes de ingreso y egreso con todos los requisitos establecidos por la autoridad e incluye complementos. Además, emite el  comprobante digital que ampara retenciones e información de pagos...</p>
                        </Tween>
                      </Reveal>
                      {/* Termina sección para darle movimiento*/}
                      {/* <p>Genera Comprobantes Fiscales Digitales por Internet (CFDI) en segundos de acuerdo con las disposiciones fiscales vigentes. Captura fácilmente facturas, notas de crédito, recibos de honorarios, de arrendamiento y otros comprobantes de ingreso y egreso con todos los requisitos establecidos por la autoridad e incluye complementos. Además, emite el  comprobante digital que ampara retenciones e información de pagos...</p> */}
                      <Link onClick={this.abrirModalFACTURE} type="button" class="btn btn col-12" style={{ backgroundColor: '#AD172B', borderRadius: '20px' }}> Conocer más..</Link>
                    </p>
                  </div>
                </div>
              </div>
              <hr color="#292563" id="Relevantes" className='hrSNT' style={{ backgroundColor: '#AD172B' }} />
            </div>
          </div>
          {/* ----------------------------------------------------------- */}




        </main >
        {/* <Carrusel_Ima /> */}
        < center >
          <div className="ContaSerInte">

            <main role="main" className=" flex-shrink-0 mt-12">

              <div class="row">
                <div class="card ev1 col-md-12 SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                  <div class="card-body justify-content-between">
                    <section className="text-left px-0">
                      {/* Inicia sección para darle movimiento*/}
                      <Reveal repeat>
                        <Tween
                          from={{ y: '-10px', x: '10px', opacity: 0, rotation: 0.5 }}
                          to={{ y: '0px', x: '0px', opacity: 1, rotation: 0 }}
                          duration={1.3}
                        >
                          <h2>ÁREAS DE INTERÉS</h2>
                        </Tween>
                      </Reveal>
                      {/* Termina sección para darle movimiento*/}
                      {/* <h2>ÁREAS DE INTERÉS</h2> */}
                    </section>
                  </div>
                </div>


                {/* <Carru1 /> */}

                {/* Areas de Interes  */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row justify-content-start" >
                      <div className="col-12 col-sm-6 col-lg-2 col-xl-2 col-xxl-2 ">
                        {/* Datos Curiosos */}
                        <div className="py-2">
                          <div class="ACD1 justify-content-between">
                            <div class="card  d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }} >
                              {/* ES AQUI */}
                              {/* <input type="image" src={Rel1} width="246" height="120" className="py-2 px-2 "></input> */}
                              <img src={Rel1} width="246" height="120" className="py-2 px-2 "></img>
                              <div className="card-body py-2 "><h4>Datos Curiosos...</h4>
                                <p class="card-text py-0">Presiona el botón y descubre contenido importante de <strong> &quot;Tecnología&quot;</strong> </p>
                              </div>
                              <div className="card-footer py-1 ">
                                {/* <CarruselTEcnm /> */}.....................................
                              </div>
                            </div>
                            <div className="botonAjuste" >
                              <CarruselTEcnm />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-2 col-xl-2 col-xxl-2 ">
                        <div className="py-2">
                          <div class="ACD1 justify-content-between">
                            <div class="card  d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }} >
                              {/* ES AQUI */}
                              {/* <input type="image" src={Rel1} width="246" height="120" className="py-2 px-2 "></input> */}
                              <input type="image" src={Rel3} onClick={this.abrirModalREL3} width="246" height="120" className="py-2 px-2 "></input>
                              <div className="card-body py-2 PNomina">
                                <h4>Primer Nómina del Año...</h4>
                                <p className="card-text py-0">Conoce toda la información sobre como comenzar con tu primer nómina del año</p>
                              </div>
                              <div className="card-footer py-1 ">
                                {/* <CarruselTEcnm /> */}.....................................
                              </div>
                            </div>
                            <div className="botonAjuste22">
                              <CarruselTEcnm3 />
                            </div>
                          </div>
                        </div>
                        {/* Primer Nómina */}
                      </div>
                      <div className="col-12 col-sm-6 col-lg-2 col-xl-2 col-xxl-2 pb-1">
                        {/* Controla Gastos */}
                        <div className=" py-2">
                          <div className=" ACD1">
                            <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                              <input type="image" src={Rel2} onClick={this.abrirModalREL2} width="246" height="120" className="py-2 px-2 "></input>
                              {/* < Boton/>
         <input class='flotin2' type="image" src={boton2}  width="30" height="30"></input> */}
                              <div className="card-body py-2 CGastos"><h4>Controla Gastos...</h4>
                                <p className="card-text py-0">En esta sección encontrarás <strong>&quot;Tips&quot;</strong> para controlar los gastos de tu negocio </p>
                              </div>
                              <div className="card-footer py-1 ">
                                {/* <CarruselTEcnm2 /> */}.....................................
                              </div>
                            </div>
                            <div className="botonAjuste23">
                              <CarruselTEcnm2 />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-2 col-xl-2 col-xxl-2 pb-1">
                        {/* Declaración Anual */}
                        <div class=" py-2">
                          <div className="ACD1">
                            <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                              <input type="image" src={Rel4} onClick={this.abrirModalREL4} width="246" height="120" className="py-2 px-2 "></input>
                              {/* < Boton /> */}
                              {/* <input class='flotin2' type="image" src={boton2}  width="30" height="30"></input> */}
                              <div className="card-body py-2 "><h4>Declaración Anual...</h4>
                                <p className="card-text py-1">Conoce los gastos que pueden ser deducibles en tu declaración Anual</p>
                              </div>
                              <div className="card-footer py-1 ">
                                {/* <CarruselTEcnm4 /> */}.....................................
                              </div>
                            </div>
                            <div className="botonAjuste24">
                              <CarruselTEcnm4 />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-2 col-xl-2 col-xxl-2 pb-1">
                        {/* Declaración Anual */}
                        <div class=" py-2">
                          <div className="ACD1">
                            <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                              <input type="image" src={Rel4} onClick={this.abrirModalREL4} width="246" height="120" className="py-2 px-2 "></input>
                              {/* < Boton /> */}
                              {/* <input class='flotin2' type="image" src={boton2}  width="30" height="30"></input> */}
                              <div className="card-body py-2 "><h4>Facturación...</h4>
                                <p className="card-text py-1">Conoce la importancia que tiene el proceso de facturación. </p>
                              </div>
                              <div className="card-footer py-1 ">
                                {/* <CarruselTEcnm4 /> */}.....................................
                              </div>
                            </div>
                            <div className="botonAjuste24">
                              <CarruselTEcnm4 />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-2 col-xl-2 col-xxl-2 pb-1">
                        {/* Declaración Anual */}
                        <div class=" py-2">
                          <div className="ACD1">
                            <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                              <input type="image" src={Rel4} onClick={this.abrirModalREL4} width="246" height="120" className="py-2 px-2 "></input>
                              {/* < Boton /> */}
                              {/* <input class='flotin2' type="image" src={boton2}  width="30" height="30"></input> */}
                              <div className="card-body py-2 "><h4>Declaración Anual...</h4>
                                <p className="card-text py-1">Conoce los gastos que pueden ser deducibles en tu declaración Anual</p>
                              </div>
                              <div className="card-footer py-1 ">
                                {/* <CarruselTEcnm4 /> */}.....................................
                              </div>
                            </div>
                            <div className="botonAjuste24">
                              <CarruselTEcnm4 />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                {/* Termina Areas de interes */}


                {/*  */}

              </div>
            </main>

            {/* Termina Div Areas de interes "Container" */}
          </div>
        </center >
        {/* <div className="container mt-4" >
        </div> */}
        < Footer />
      </>


    )

  }

}

export default AComercial1;

