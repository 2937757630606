import React from 'react';
import PQ1 from '../IMG/SolucionesTecnologicas/OCA_PQ_1.jpg';
import PQ2 from '../IMG/SolucionesTecnologicas/OCA_PQ_2.jpg';
import PQ3 from '../IMG/SolucionesTecnologicas/OCA_PQ_3.jpg';
import PQ4 from '../IMG/SolucionesTecnologicas/OCA_PQ_4.jpg';
import FondoForm from '../IMG/SolucionesTecnologicas/OCA_FORM_FONDO.jpg';
import FondoForm2 from '../IMG/SolucionesTecnologicas/OCA_FORM_FONDO_1.jpg';
import FondoForm3 from '../IMG/SolucionesTecnologicas/OCA_FORM_FONDO_2.jpg';
import Boton from './Boton'
import A from '../IMG/modal/Ev1.png';
import A1 from '../IMG/modal/icono-inf-tecnol-gica.png';
import L1 from '../IMG/LogosAspel/ASPEL-ICONO VERT_BANCO.png';
import L2 from '../IMG/LogosAspel/ASPEL-ICONO VERT_CAJA.png';
import L3 from '../IMG/LogosAspel/ASPEL-ICONO VERT_COI.png';
import L4 from '../IMG/LogosAspel/ASPEL-ICONO VERT_FACTURE.png';
import L5 from '../IMG/LogosAspel/ASPEL-ICONO VERT_NOI.png';
import L6 from '../IMG/LogosAspel/ASPEL-ICONO VERT_PROD.png';
import L7 from '../IMG/LogosAspel/ASPEL-ICONO VERT_SAE.png';
import A2 from '../IMG/modal/icon-backup.png';
import A3 from '../IMG/modal/icon-seguridad.png';
import A4 from '../IMG/modal/ms-office.png';
import A5 from '../IMG/modal/distribuidorintegral.png';
import B from '../IMG/modal/Ev2.png';
import C from '../IMG/modal/Ev3.png';
import D from '../IMG/modal/Ev4.png';
import E from '../IMG/modal/Ev5.png';
import logo from '../IMG/Logo/oca-consultoria-logo-ii.png';
import BF1 from '../IMG/BFISKUR/cfdi-cancelados 1.png';
import BF2 from '../IMG/BFISKUR/comprobantes-pago-faltantesl 1.png';
import BF3 from '../IMG/BFISKUR/cumplimiento-obligaciones 1.png';
import BF4 from '../IMG/BFISKUR/efos 1.png';
import BF5 from '../IMG/BFISKUR/medidor-riesgo-fiscal 1.png';
import BF6 from '../IMG/BFISKUR/cfdi-recibido-reglas 1.png';
import BF7 from '../IMG/BFISKUR/repse-registro-proveedores 1.png';
import BF8 from '../IMG/BFISKUR/triple-conciliacion 1.png';
import ServOCA from '../IMG/OCA/Servicios/ServiciosOCA.jpg';
import LogoAspel1 from '../IMG/LogosAspel/LogoAspel.png';
import PQE from '../IMG/LogosAspel/LogoAspel.png';
import Medall from '../IMG/Logo/medalla-aspel-removebg-preview_orig.png';
import '../Component/styles/Servicios.css';
import { Reveal, Tween } from 'react-gsap';
import B1 from '../IMG/Logo/Bfis.png';
import FormularioSNT from '../paginas/Formulario/Conexion';
import Solucion from '../IMG/SolucionesTecnologicas/OCA_C_6.jpg';
import Eligenos from '../IMG/Diseños_SNT/TecnoOCA2.png';
import {
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody, CardFooter
} from 'reactstrap';

class SolucionesTec extends React.Component {

    render() {

        return (
            <>
                {/* <br /> */}
                {/* <Reveal repeat>
                    <Tween
                        from={{ x: '-15%', opacity: 0, rotation: 0.5 }}
                        to={{ x: '0%', opacity: 1, rotation: 0 }}
                        duration={1.1}
                    >
                        <h2 ClassName="py-4 titSNT" align="center" style={{backgroundColor:'red'}}><b>Soluciones tecnológicas de gestión empresarial </b></h2>
                    </Tween>
                </Reveal> */}
                <br />
                <div className="row container-fluid">
                    <div className="col-sm-8">
                        <p className="titSNT2 py-1" align="center"><span className="SNTtit">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¿Por qué Elegirnos?</span></p>
                        {/* <CardDeck className="SombraSNT">
                                                 <Card style={{ backgroundColor: '#eff3fb' }}>
                                                        <CardBody className="pt-1">
                                                               <Reveal repeat>
                                                                      <Tween
                                                                             from={{ x: '20%', opacity: 0, rotation: 0.5 }}
                                                                             to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                                             duration={1.3}
                                                                      >
                                                                             <p className="titSNT2" align="center"><span className="SNTtit">¿Porqué Elegirnos?</span></p>
                                                                      </Tween>
                                                               </Reveal>
                                                               <Reveal repeat>
                                                                      <Tween
                                                                             from={{ opacity: 0, rotation: 0.5 }}
                                                                             to={{ opacity: 1, rotation: 0 }}
                                                                             duration={2}
                                                                      >
                                                                             <p className="CardFondo text-center">Somos una empresa especializada en tecnología de la ​información y consultoría.
                                                                                    Contamos con un equipo de ingenieros rigurosamente evaluados y certificados,
                                                                                    capaces de brindar soluciones integrales de alta calidad sin importar el tamaño
                                                                                    o complejidad del proyecto.</p>
                                                                             <center>
                                                                                    <img src={Medall} className="" width="8%" ></img>
                                                                                    <img src={PQE} className="imaSNT11" width="20%" ></img>
                                                                                    <img src={Medall} className="" width="8%" ></img>
                                                                                    <br /><br /><br />
                                                                                    <br /><br /><br />
                                                                                    <br /><br /><br />
                                                                                    <br /><br /><br />
                                                                                    <br /><br />
                                                                             </center>

                                                                      </Tween>
                                                               </Reveal>

                                                        </CardBody>
                                                 </Card>
                                          </CardDeck> */}
                        {/* Inicia ¿Por qué elegirnos? */}
                        {/* Inicia Seccion ¿Por qué elegirnos? */}
                        <div className="row ">
                            <div className="col-sm-12 text-center">
                                <div className="row">
                                    <div className="col-sm-4 text-right">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -1, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span><img src={PQ1} className="" width="50%" ></img></span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                    <div className="col-sm-8 Mov_text text-justify px-3">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -1, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span className="LetraPQ">
                                                    Somos una empresa especializada en <strong>tecnología de la ​información y consultoría. </strong>
                                                    <script> alert("hi!") </script>
                                                </span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 text-center">
                                <div className="row">
                                    <div className="col-sm-4 text-right">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -5, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span><img src={PQ2} className="" width="50%" ></img></span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                    <div className="col-sm-8 Mov_text2 text-justify px-3">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -5, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span className="LetraPQ">
                                                    Contamos con un equipo de <strong>ingenieros</strong> rigurosamente <strong>evaluados y certificados</strong> que te  apoyarán y te  brindaran soporte cuando lo necesites.
                                                </span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 text-center">
                                <div className="row">
                                    <div className="col-sm-4 text-right">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -12, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span><img src={PQ3} className="" width="50%" ></img></span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                    <div className="col-sm-8 Mov_text2 text-justify px-3">
                                        <Reveal repeat>
                                            <Tween
                                                from={{ x: '-15%', opacity: -12, rotation: 0.5 }}
                                                to={{ x: '0%', opacity: 1, rotation: 0 }}
                                                duration={1.1}
                                            >
                                                <span className="LetraPQ text-justify">
                                                    Brindamos <strong>soluciones integrales</strong> de alta calidad sin importar el tamaño o complejidad del proyecto.
                                                </span>
                                            </Tween>
                                        </Reveal>
                                    </div>
                                </div>
                            </div>
                            {/* <img src={PQ3} className="" width="40%" ></img> */}

                            {/* <div className="col-sm-6 text-left">
                                <p className="">
                                    Somos una empresa especializada en tecnología de la ​información y consultoría.
                                </p>
                                <p className="">
                                Contamos con un equipo de ingenieros rigurosamente evaluados y certificados que te apoyarán y te brindaran soporte cuando lo necesites.
                                </p>
                                <p className="">
                                Brindamos soluciones integrales de alta calidad sin importar el tamaño o complejidad del proyecto.
                                </p>
                            </div> */}

                        </div>
                        {/* Termina Seccion ¿Por qué elegirnos? */}

                        {/* Termina ¿Por qué elegirnos? */}


                        {/* <img src={PQ4} className="" width="100%" height='510px' ></img> */}
                    </div>
                    <div className="col-sm-4">
                        <img src={Eligenos} className="mr-auto SoTecno821" width="100%" height="100%" alt="Acompañamos la evolición de tu negocio" />
                        {/* <p className="container text-center SNTtitColor titSNT2">
                            Contáctanos
                        </p>
                        <div className="row">
                            <div className="col-sm-2">
                            </div>
                            <div className="MovSNT1 col-sm-7 p-1 ml-3">
                                <FormularioSNT />
                            </div>
                            <div className="col-sm-2">
                            </div>
                        </div> */}
                    </div>
                </div>
            </>

        )

    }

}

export default SolucionesTec;